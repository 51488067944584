<template>
  <div class="cont-warp">
    <div class="crumbs-cont">
      <div class="crumbs">所在位置：
        <router-link to="/"> 首页</router-link>
        /
        <router-link to="/securityTrain"> 证书培训</router-link>
        / 数据安全评估
      </div>
    </div>
    <div class="cont-img">
      <img src="../../assets/images/securityTrain/pc/assess/pic01.png">
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/h5AssessPage');
    } else {
      this.$router.replace('/assessPage');
    }
  },
}
</script>
<style lang="less" scoped>
.crumbs-cont {
  text-align: left;
  width: 100%;
  line-height: 34px;
  height: 34px;
  background: #F3F6F8;


  .crumbs {
    width: 1200px;
    margin: 0 auto;

    a {
      font-size: 14px;
      color: #333333;

      &:hover {
        color: #0079F4;
      }
    }

    font-size: 14px;
    color: #333333;
  }
}

.cont-img {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;

  img {
    max-width: 100%;
    margin-top: 50px;
  }

  .erweima-img {
    max-width: 730px;
    margin: 0 auto 50px;
  }
}

.item-img {
  width: 100%;
  //height: 650px;
  background: #0079F4;
  padding: 30px 0;

  .pic-img {
    width: 1200px;
    margin: 0 auto;

    img {
      max-width: 100%;
      margin-top: 50px;
    }
  }

}
</style>
